// basic imports
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// firebase imports
import { auth, firestore } from './firebaseConfig';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';

// layout imports
import logo from './img/GMI_negative_RGB.png';
import { Container, Grid, Paper, TextField, Button, Typography, CircularProgress } from '@mui/material';



const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if(password !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Update the user profile with their name and surname combined into the displayName field
      await updateProfile(user, {
        displayName: `${name} ${surname}`
      });

      await setDoc(doc(firestore, "users", user.uid), {
        email: user.email,
        name: name,
        surname: surname,
        role: 'user'
      });
      navigate('/'); // Redirect to Login after successful registration and profile update
    } catch (error) {
      alert(error.message);
    }
  };

  return (
   
  <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={6} sx={{
          backgroundColor: '#09f',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'column' },
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          order: { xs: 2, md: 1 }
        }}>
          <img src={logo} alt="logo" style={{ maxWidth: '150px', marginBottom: { xs: 2, md: 2 } }}/>
          <Typography variant="h5" color="inherit">Copilot</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          order: { xs: 1, md: 2 }
        }}>
          <Paper elevation={6} sx={{ padding: 3, width: '100%', maxWidth: 400  }}>
           <form onSubmit={handleRegister}>
              <TextField label="Name" fullWidth margin="normal" required value={name} onChange={e => setName(e.target.value)} />
              <TextField label="Surname" fullWidth margin="normal" required value={surname} onChange={e => setSurname(e.target.value)} />
              <TextField label="Email Address" type="email" fullWidth margin="normal" required value={email} onChange={e => setEmail(e.target.value)} />
              <TextField label="Password" type="password" fullWidth margin="normal" required value={password} onChange={e => setPassword(e.target.value)} />
              <TextField label="Confirm Password" type="password" fullWidth margin="normal" required value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
              <Button onClick={(e) => {handleRegister(e)}} type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>Register</Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
  
};

export default Register;
