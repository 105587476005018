//basic imports
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// component imports
import Login from "./Login";
import Register from "./Register";
import Copilot from "./Copilot"; // Adjust the import path as needed
import Settings from "./SettingsUser";
import "./App.css";
import "./CustomStyle.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/copilot" element={<Copilot />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Router>
  );
}

export default App;
