// base imports
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// firebase imports
import { useAuth } from "./AuthContext";
import { firestore } from "./firebaseConfig";
import {
  getDoc,
  collection,
  doc,
  getDocs,
  query,
  where,
  addDoc,
  serverTimestamp,
  onSnapshot,
  deleteDoc,
  updateDoc,
  orderBy,
} from "firebase/firestore";

// component imports
import Settings from "./SettingsUser";
import ConversationSettingsDialog from "./components/ConversationSettingsDialog";

// layout imports
import {
  Container,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  TextField,
  Box,
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ShareIcon from "@mui/icons-material/Share";
import SettingsIcon from "@mui/icons-material/Settings";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import gmiLogo from "./img/GMI_negative_RGB.png";
import copilotAvatar from "./img/A_simbolo-2.png";

const Copilot = () => {
  // Auth control check and navigation
  const { currentUser, signOut } = useAuth();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("");

  // NEW CHAT MANAGEMENT
  const [conversations, setConversations] = useState([]);
  const [conversationAnchorEl, setConversationAnchorEl] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [conversationDialogOpen, setConversationDialogOpen] = useState(false);
  const [conversationNewName, setConversationNewName] = useState("");
  // CHAT AREA
  const [messageInput, setMessageInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [userAvatar, setUserAvatar] = useState("");

  //  CORRECTION DIALOG
  const [correctionDialogOpen, setCorrectionDialogOpen] = useState(false);
  const [correctionInput, setCorrectionInput] = useState("");
  const [currentMessage, setCurrentMessage] = useState(null);

  // CONVERSATION SETTINGS DIALOG
  const [conversationSettingsDialogData, setConversationSettingsDialogData] =
    useState(null);
  const [conversationSettingsDialogOpen, setConversationSettingsDialogOpen] =
    useState(false);

  // GENERAL SETTINGS DIALOG
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  // DRAWER
  const [open, setOpen] = React.useState(false);

  // FUNCTIONS
  const handleUserAvatar = async () => {};
  const handleSettingsDialogOpen = () => {
    setSettingsDialogOpen(true);
  };

  const handleSettingsDialogClose = () => {
    setSettingsDialogOpen(false);
  };

  const handleLoadConversations = () => {
    console.log("Current user: ", currentUser);
    if (!currentUser) {
      navigate("/"); // Redirect to login if no currentUser is found
    }
    return onSnapshot(
      query(
        collection(firestore, "conversations"),
        where("user_uid", "==", currentUser?.uid)
      ),
      (snapshot) => {
        setConversations(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      }
    );
  };

  const handleSignOut = async () => {
    try {
      if (window.confirm("Are you sure you want to sign out?")) {
        await signOut();
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMessageChange = (event) => setMessageInput(event.target.value);

  const handleNewChat = async () => {
    setLoading(true); // Start loading indicator
    console.log(currentUser);
    try {
      const newConversation = {
        user_uid: currentUser.uid,
        creation_date: serverTimestamp(),
        default_settings: (
          await getDoc(doc(firestore, "users", currentUser.uid))
        ).data().default_settings,
        name: `Chat ${new Date().getTime()}`,
      };

      const docRef = await addDoc(
        collection(firestore, "conversations"),
        newConversation
      );
      console.log("New conversation created successfully!");
      return docRef.id; // Return the new conversation ID
    } catch (error) {
      console.error("Failed to create new conversation:", error);
      alert("Error starting a new chat. Please try again.");
    } finally {
      setLoading(false); // End loading indicator
    }
  };

  const handleSendMessage = async (event) => {
    console.log("sending message to endpoint....");
    event.preventDefault();

    if (!messageInput.trim()) return;

    setLoading(true);

    const newMessage = {
      type: "user",
      content: messageInput,
      avatar: userAvatar, // Avatar for user message
      id: `${new Date().getTime()}_${currentUser.uid}`, // Assuming immediate front-end timestamp handling
    };

    setMessages((messages) => [...messages, newMessage]);
    setMessageInput("");

    let currentConversationId = selectedConversation;

    if (!currentConversationId) {
      console.log("Creating new chat...");
      console.log(
        "This is the value of conversation before creation: ",
        selectedConversation
      );
      currentConversationId = await handleNewChat();
      console.log(
        "This is the value of conversation after creation: ",
        selectedConversation
      );

      if (!currentConversationId) {
        console.log("Fail to create conversation");
        setLoading(false);
        return; // If no conversation was created, do not proceed
      }
      setSelectedConversation(currentConversationId);
      // setMessages(messages => [...messages, newMessage]);
    }

    try {
      const docRef = doc(firestore, "conversations", selectedConversation);
      const userDocRef = doc(firestore, "users", currentUser.uid);
      // Fetch the document
      const docSnap = await getDoc(docRef);
      const userDocSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        // Use the conversation's default settings if available
        var fetchedSettings =
          docSnap.data().default_settings ||
          userDocSnap.data().default_settings;

        // Continue with the rest of your code using `settings`
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching conversation settings:", error);
    }

    console.log("This is the new messages with the input from user:", messages);
    const requestBody = {
      question: messageInput,
      setup: false,
      settings: fetchedSettings,
      conversation_id: selectedConversation,
      evaluation: false,
      user_uid: currentUser.uid,
      fakeResponse: false,
    };

    try {
      // fetch request
      const encodedAzureEndpoint =
        // "https%3A%2F%2Fainexxo-brainiq-v2.westeurope.inference.ml.azure.com%2Fscore";
        "https%3A%2F%2Fdev.s1.ainexxo.com%2Fapi%2Fv1%2Fbrainiq%2Fquery";
      const cloudFunction =
        "https://us-central1-ainexxo-234f8.cloudfunctions.net/cors/proxy?url=";
      const url = cloudFunction + encodedAzureEndpoint;

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      let data = await response.text(); // Assuming the response is in JSON format
      data = JSON.parse(data);

      if (
        data &&
        data.out &&
        data.out.answer &&
        !messages.some((msg) => msg.id === data.out.messageId)
      ) {
        console.log("Set messages in Answer received (no image)");
        setMessages((messages) => [
          ...messages,
          {
            type: "responder",
            content: data.out.answer,
            isImage: false,
            avatar: copilotAvatar,
            id: data.out.messageId,
          },
        ]);
      } else if (data && data.out && data.out.image) {
        console.log(
          "set messages in Image response received: ",
          data.out.image
        );
        // setMessages(prevMessages => [...prevMessages, { type: 'responder', content: data.out.image, isImage: true, avatar: copilotAvatar, id: data.out.messageId } ]);
      } else {
        console.error("Answer was undefined.");
      }
    } catch (error) {
      console.log("Error in request: ", error);
    } finally {
      setLoading(false);
    }
  };

  // Navigation to return to Copilot page
  const handleSettings = () => {
    navigate("/settings");
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Handle menu actions
  const handleConversationClick = (event, conversation) => {
    event.preventDefault();
    setConversationAnchorEl(conversation.id);
  };

  const handleConversationMenuClose = () => {
    setConversationAnchorEl(null);
  };

  // Handle delete
  const handleConversationDelete = async () => {
    if (window.confirm("Are you sure you want to delete this conversation?")) {
      try {
        await deleteDoc(doc(firestore, "conversations", selectedConversation));
        handleConversationMenuClose();
      } catch (error) {
        console.error("Failed to delete conversation:", error);
        alert("Error deleting conversation. Please try again.");
      }
    }
  };

  // Handle rename dialog
  const handleConversationRenameClick = () => {
    setConversationDialogOpen(true);
    handleConversationMenuClose();
  };

  const handleConversationDialogClose = () => {
    setConversationDialogOpen(false);
  };

  const handleConversationRename = async () => {
    if (!selectedConversation) {
      console.error("No conversation selected for renaming.");
      return; // Stop the function if there is no selected conversation
    }

    if (conversationNewName.trim() === "") {
      console.error("The new name for the conversation is empty.");
      return; // Stop the function if the new name is empty
    }

    try {
      const docRef = doc(firestore, "conversations", selectedConversation); // Correctly referencing the document
      await updateDoc(docRef, {
        name: conversationNewName, // Assuming 'name' is the field you want to update
      });
      console.log("Conversation renamed successfully!");
      setConversationNewName(""); // Clearing the new name input after successful rename
      handleConversationDialogClose(); // Close the dialog
    } catch (error) {
      console.error("Error renaming conversation:", error);
      alert("Error renaming conversation. Please try again.");
    }
  };

  const handleOpenCorrectionDialog = (msg) => {
    setCurrentMessage(msg);
    setCorrectionDialogOpen(true);
  };

  const handleCloseCorrectionDialog = () => {
    // console.log('Thumbs down for:', msg.id);
    console.log(
      "Current message inside function handleCloseCorrectionDialog ",
      currentMessage
    );
    setCorrectionDialogOpen(false);
    setCorrectionInput("");
  };

  const handleCorrectionSubmit = async () => {
    // Ensure there is a message selected and a correction input
    console.log(
      "Current message inside function handle correction submit ",
      currentMessage
    );
    if (!currentMessage || !correctionInput.trim()) {
      alert("Please enter a valid correction.");
      return;
    }

    try {
      // Assuming each message is stored with a unique ID that can be accessed
      // If not, you might need to adjust how you retrieve and store message data
      const messageDocRef = doc(firestore, "messages", currentMessage.id);

      // Update the correction attribute of the message
      await updateDoc(messageDocRef, {
        correction: correctionInput,
        evaluation: true, // Assuming you might want to mark the message as evaluated for correction
      });

      // Update the local state to reflect the correction in the UI without needing to refetch from Firestore
      console.log("set messages in handleCorrectionSubmit");
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === currentMessage.id
            ? { ...msg, correction: correctionInput, evaluation: true }
            : msg
        )
      );
      console.log(
        "This are the new messages with the correction done: ",
        messages
      );

      // Close the dialog and clear the correction input
      setCorrectionDialogOpen(false);
      setCorrectionInput("");
      alert("Correction submitted successfully.");
      handleCloseCorrectionDialog();
    } catch (error) {
      console.error("Failed to submit correction:", error);
      alert("Error submitting correction. Please try again.");
    }
  };

  const handleThumbUp = async (msg) => {
    alert("Thanks! Your message has been marked as reviewed.");
  };

  const handleLoadHistory = async () => {
    if (!selectedConversation) {
      console.log("No selected conversation for loading history");
      setMessages([]);
      return () => {}; // Return a noop cleanup function if no conversation is selected
    }

    console.log(
      "Setting up snapshot listener for conversation:",
      selectedConversation
    );

    try {
      const q = query(
        collection(firestore, "messages"),
        where("conversation_id", "==", selectedConversation),
        orderBy("timestamp")
      );
      const querySnapshot = await getDocs(q);
      const loadedMessages = querySnapshot.docs.flatMap((doc) => {
        const msgData = doc.data();
        return [
          ...(msgData.question
            ? [
                {
                  id: doc.id + "_q", // Unique ID for the question
                  type: "user",
                  content: msgData.question,
                  avatar: userAvatar,
                  timestamp: msgData.timestamp,
                },
              ]
            : []),
          ...(msgData.answer
            ? [
                {
                  id: doc.id, // Unique ID for the answer
                  type: "responder",
                  content: msgData.answer,
                  avatar: copilotAvatar,
                  timestamp: msgData.timestamp,
                  correction: msgData.correction,
                  evaluation: msgData.evaluation,
                },
              ]
            : []),
        ];
      });
      console.log("Loaded history messages: ", loadedMessages);
      setMessages(loadedMessages);
    } catch (error) {}
  };
  // CONVERSATION SETTINGS
  // Toggles the visibility of the conversation settings dialog
  const toggleConversationSettingsDialog = (open) => {
    setConversationSettingsDialogOpen(open);
  };

  // Closes the conversation settings dialog
  const closeConversationSettingsDialog = () => {
    toggleConversationSettingsDialog(false);
  };

  const handleConversationSettingsDialogOpen = async (conversationId) => {
    setSelectedConversation(conversationId); // Ensure the conversation is selected
    const conversationDoc = await getDoc(
      doc(firestore, "conversations", selectedConversation)
    );
    if (conversationDoc.exists()) {
      setConversationSettingsDialogData({
        ...conversationDoc.data(),
        id: selectedConversation,
      });
      setConversationSettingsDialogOpen(true);
      // toggleConversationSettingsDialog(true);
    } else {
      console.error("No such conversation found with ID:", conversationId);
      alert("Failed to fetch conversation details.");
      setConversationSettingsDialogData(null);
    }
  };

  const handleUpdateConversationSettings = async () => {
    if (selectedConversation && conversationSettingsDialogData) {
      const conversationRef = doc(
        firestore,
        "conversations",
        selectedConversation
      );
      try {
        await updateDoc(conversationRef, conversationSettingsDialogData);
        alert("Settings updated successfully.");
        setConversationSettingsDialogOpen(false);
      } catch (error) {
        alert("Failed to update settings.");
        console.error("Error updating settings:", error);
      }
    }
  };

  ///////////////////////////////////////////////////////////////
  // USE EFFECTS
  useEffect(() => {
    const fetchUserRole = async () => {
      const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserRole(userData.role); // Assuming role is stored directly under the user document
      }
    };

    fetchUserRole();
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      console.log("No user logged in");
      navigate("/"); // Return a noop cleanup function if no conversation is selected
      return;
    }
    console.log("Loading or cleaning message history...");

    // Load history only if there's a selected conversation
    if (selectedConversation) {
      handleLoadHistory();
    }

    // Cleanup function
    return () => {
      // Check current messages and keep a user message if it's the only one
      if (messages.length === 1 && messages[0].type === "user") {
        console.log("Retaining user message in the chat...");
      } else {
        console.log("Clearing messages on cleanup...");
        setMessages([]); // Clear messages only if it's not a single 'user' type message
      }
    };
  }, [selectedConversation, currentUser]); // Dependencies include selectedConversation and currentUser

  useEffect(() => {
    if (!currentUser) {
      console.log("No user logged in");
      navigate("/"); // Return a noop cleanup function if no conversation is selected
      return;
    }

    console.log("loading conversations for this user....");
    const unsubscribe = handleLoadConversations();

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  useEffect(() => {
    // Check if the messagesEndRef.current is not null and messages array is not empty
    if (messagesEndRef.current && messages.length > 0) {
      // Scroll the last message into view
      messagesEndRef.current.lastElementChild.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages]); // Dependency o

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <AppBar position="static" sx={{ backgroundColor: "#09f" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>
            <img src={gmiLogo} alt="GMI Logo" style={{ height: "40px" }} />
          </Toolbar>
        </AppBar>

        {/* Collapsible Drawer */}
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile
          sx={{
            width: 250,
            [`& .MuiDrawer-paper`]: { width: 250, boxSizing: "border-box" },
          }}
        >
          <Toolbar>
            <Button
              fullWidth
              color="inherit"
              onClick={handleNewChat}
              sx={{ width: "100%" }}
            >
              New Chat
            </Button>
          </Toolbar>
          <Divider />
          <List sx={{ overflow: "auto", flexGrow: 1 }}>
            {conversations.map((conversation) => (
              <ListItem
                key={conversation.id}
                button
                onClick={(e) => {
                  setSelectedConversation(conversation.id);
                  console.log(
                    "This is the conversation selected: ",
                    conversation.id
                  );
                }}
              >
                <ListItemText primary={conversation.name} />
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Stop click event from bubbling to the list item
                    setSelectedConversation(conversation.id);
                    setConversationAnchorEl(e.currentTarget);
                    console.log(conversation.id);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={conversationAnchorEl}
                  open={Boolean(conversationAnchorEl)}
                  onClose={handleConversationMenuClose}
                >
                  <MenuItem onClick={handleConversationRenameClick}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Rename</ListItemText>
                  </MenuItem>
                  {userRole === "developer" && (
                    <MenuItem
                      onClick={() =>
                        handleConversationSettingsDialogOpen(conversation.id)
                      }
                    >
                      <ListItemIcon>
                        <SettingsIcon />
                      </ListItemIcon>
                      <ListItemText>Settings</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem disabled>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                  <MenuItem disabled>
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText>Share</ListItemText>
                  </MenuItem>
                </Menu>
              </ListItem>
            ))}
          </List>

          <Dialog
            open={conversationDialogOpen}
            onClose={handleConversationDialogClose}
          >
            <DialogTitle>Rename Conversation</DialogTitle>
            <div style={{ padding: 20 }}>
              <TextField
                autoFocus
                margin="dense"
                label="New Name"
                type="text"
                fullWidth
                variant="standard"
                value={conversationNewName}
                onChange={(e) => setConversationNewName(e.target.value)}
              />
            </div>
            <DialogActions>
              <Button onClick={handleConversationDialogClose}>Cancel</Button>
              <Button onClick={handleConversationRename}>Rename</Button>
            </DialogActions>
          </Dialog>
          <Divider />

          <Toolbar>
            <Button
              fullWidth
              color="inherit"
              onClick={handleSettingsDialogOpen}
            >
              Settings
            </Button>
            <Button fullWidth color="error" onClick={handleSignOut}>
              Logout
            </Button>
          </Toolbar>
        </Drawer>

        {/* Chat Area */}
        <Dialog
          open={correctionDialogOpen}
          onClose={handleCloseCorrectionDialog}
        >
          <DialogTitle>Input correct answer</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If the response was not helpful, please provide the correct
              answer.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Correct Answer"
              type="text"
              fullWidth
              variant="standard"
              value={correctionInput}
              onChange={(e) => setCorrectionInput(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCorrectionDialog}>Cancel</Button>
            <Button onClick={handleCorrectionSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>

        {/* General Settings Dialog */}
        <Dialog
          fullWidth
          maxWidth="md"
          open={settingsDialogOpen}
          onClose={handleSettingsDialogClose}
        >
          <DialogTitle>Settings</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <List>
                  <ListItem button>
                    <ListItemText primary="Personal Data" />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Model Options" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={8}>
                {/* Component to render based on selection */}
                <Settings />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSettingsDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>

        {/* Conversations Settings Dialog */}
        <ConversationSettingsDialog
          open={conversationSettingsDialogOpen}
          onClose={closeConversationSettingsDialog}
          conversationData={conversationSettingsDialogData}
          role={userRole}
          onSave={handleUpdateConversationSettings}
        />

        <Box sx={{ flexGrow: 1, overflow: "auto" }} ref={messagesEndRef}>
          {" "}
          {/*onScroll={handleChatScroll} */}
          {messages.map((msg, index) => (
            <div key={msg.id || index}>
              <Box display="flex" alignItems="center" p={1}>
                <Avatar
                  src={msg.avatar}
                  sx={{ width: 24, height: 24, marginRight: 1 }}
                />
                <Typography variant="body1">{msg.content}</Typography>
              </Box>
              {msg.type === "responder" && (
                <>
                  <Box
                    sx={{ display: "flex", alignItems: "center", marginTop: 1 }}
                  >
                    <IconButton size="small" onClick={() => handleThumbUp(msg)}>
                      <ThumbUpIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleOpenCorrectionDialog(msg)}
                    >
                      <ThumbDownIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  {msg.evaluation && (
                    <Box
                      sx={{
                        mt: 1,
                        p: 1,
                        borderRadius: "10px",
                        border: "1px solid red",
                        maxWidth: "80%",
                      }}
                    >
                      <Typography variant="body2" style={{ color: "red" }}>
                        Correction: {msg.correction}
                      </Typography>
                    </Box>
                  )}
                  <div style={{ marginBottom: "20px" }}></div>
                </>
              )}
            </div>
          ))}
          {loading && (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          )}
        </Box>

        {/* Message Input always at the bottom */}
        <Box
          component="form"
          onSubmit={handleSendMessage}
          noValidate
          autoComplete="off"
          sx={{ display: "flex", p: 2, borderTop: "1px solid #ddd" }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={messageInput}
            onChange={handleMessageChange}
            sx={{ mr: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Send
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default Copilot;
