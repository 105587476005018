// basic imports
import React, { useState, useEffect } from "react";

// layout imports
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

function ConversationSettingsDialog({
  open,
  onClose,
  conversationData,
  userRole,
  onSave,
}) {
  const [settings, setSettings] = useState({});

  // Handle changes to individual settings
  const handleSettingsChange = (index, field, value) => {
    const updatedSettings = [...settings];
    updatedSettings[index][field] = value;
    setSettings(updatedSettings);
  };

  // Add a new setting field
  const addSetting = () => {
    // Generate a unique key for the new setting
    const newKey = `new_key_${Object.keys(settings).length + 1}`;
    setSettings([...settings, { key: "", value: "", type: "string" }]);
  };

  // Save the settings back to the parent component
  const handleSave = () => {
    if (onSave && conversationData) {
      onSave({
        ...conversationData,
        default_settings: settings,
      });
      onClose(); // Close the dialog on save
    }
  };

  // Parse values by their designated type
  const parseValueByType = (value, type) => {
    switch (type) {
      case "number":
        return Number(value);
      case "bool":
        return value === "true";
      case "map":
      case "array":
        try {
          return JSON.parse(value);
        } catch {
          return value;
        }
      default:
        return value;
    }
  };

  // Use useEffect to update settings when conversationData changes
  useEffect(() => {
    if (conversationData && conversationData.default_settings) {
      const initialSettings = Object.entries(
        conversationData.default_settings
      ).map(([key, value]) => ({
        key,
        value: JSON.stringify(value),
        type: typeof value,
      }));
      setSettings(initialSettings);
    }
  }, [conversationData]);

  if (!conversationData) {
    // Render nothing or a loading spinner until data is available
    return null;
  }

  console.log("this is the conversation data: ", conversationData);
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Conversation Settings</DialogTitle>
      <DialogContent sx={{ mt: 1, overflow: "visible" }}>
        <TextField
          label="Conversation ID"
          fullWidth
          variant="outlined"
          value={conversationData.id}
          InputProps={{
            readOnly: true,
            style: { color: "gray" },
          }}
        />
        {conversationData && (
          <Grid container spacing={2}>
            {/* Conversation ID */}
            <Grid item xs={12}></Grid>
            {/* Conversation Name */}
            <Grid item xs={12}>
              <TextField
                label="Conversation Name"
                fullWidth
                variant="outlined"
                value={conversationData.name}
                onChange={(e) => handleSettingsChange("name", e.target.value)}
              />
            </Grid>

            {/* Default Settings: Dynamically generate fields */}
            {settings.map((setting, index) => (
              <Grid container spacing={2} key={index} sx={{ m: 1 }}>
                <Grid item xs={4}>
                  <TextField
                    label="Key"
                    variant="outlined"
                    value={setting.key}
                    onChange={(e) =>
                      handleSettingsChange(index, "key", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Value"
                    variant="outlined"
                    value={setting.value}
                    onChange={(e) =>
                      handleSettingsChange(index, "value", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={setting.type}
                      label="Type"
                      onChange={(e) =>
                        handleSettingsChange(index, "type", e.target.value)
                      }
                    >
                      <MenuItem value="string">String</MenuItem>
                      <MenuItem value="bool">Boolean</MenuItem>
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="map">Map (JSON)</MenuItem>
                      <MenuItem value="array">Array</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <IconButton onClick={addSetting}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Grid>

            {/* Creation Date */}
            <Grid item xs={12}>
              <TextField
                label="Creation Date"
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                  style: { color: "gray" },
                }}
                value={
                  conversationData.creation_date
                    ? new Date(
                        conversationData.creation_date.seconds * 1000
                      ).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })
                    : ""
                }
              />
            </Grid>
            {/* User ID */}
            <Grid item xs={12}>
              <TextField
                label="User ID"
                fullWidth
                variant="outlined"
                value={conversationData.user_uid}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConversationSettingsDialog;
