// basic imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation

// firebase imports
import { auth } from './firebaseConfig';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';

// layout imports
import { Container, Grid, Paper, TextField, Button, Typography, CircularProgress } from '@mui/material';
import logo from './img/GMI_negative_RGB.png';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Assume loading initially
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/copilot'); // If already logged in, go to chat
      } else {
        setIsLoading(false); // Not logged in, show login form
      }
    });
    return unsubscribe; // Cleanup subscription
  }, [navigate]);


  if (isLoading) {
    return <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>;
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/copilot'); // Redirect to ChatContainer after login
    } catch (error) {
      alert(error.message);
      
    }
  };

 
  return (
    <Container sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={6} sx={{
          backgroundColor: '#09f',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'column' },
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          order: { xs: 2, md: 1 }
        }}>
          <img src={logo} alt="logo" style={{ maxWidth: '150px', marginBottom: { xs: 2, md: 2 } }}/>
          <Typography variant="h5" color="inherit">Copilot</Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          order: { xs: 1, md: 2 }
        }}>
          <Paper elevation={6} sx={{ padding: 3, width: '100%', maxWidth: 400 }}>
            <form onSubmit={handleLogin}>
              <TextField
                label="Email Address"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button type="submit" color="primary" variant="contained" fullWidth sx={{ mb: 1 }}>Login</Button>
              <Button onClick={() => navigate('/register')} color="primary" variant="outlined" fullWidth>
                Register
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;

