// basic imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

// firebase imports
import { firestore, storage } from "./firebaseConfig";
import { doc, getDoc, setDoc } from "firebase/firestore";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

//layout imports
import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

const SettingsUser = () => {
  // Auth check and navigation
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Set current payload
  const [userDefaultSettings, setUserDefaultSettings] = useState({
    generativeModels: "",
    embeddingCollections: "",
    embeddingSamples: undefined,
  });

  const [newSettings, setNewSettings] = useState({
    generativeModels: "",
    embeddingCollections: "",
    embeddingSamples: undefined,
  });
  // Populate the visible fields
  const [options, setOptions] = useState({
    // for developers and testers
    generativeModels: [],
    embeddingCollections: [],
    embeddingSamples: undefined,
  });

  // Personal data section
  const [personalData, setPersonalData] = useState({
    // personal data
    name: "",
    surname: "",
    company: "",
  });

  const [role, setRole] = useState("");

  // avatar handling
  const [avatarFile, setAvatarFile] = useState(null);

  const handleAvatarChange = (event) => {
    if (event.target.files[0]) {
      setAvatarFile(event.target.files[0]);
    }
  };

  // FETCH USER ROLE
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = doc(firestore, "users", currentUser.uid);
        let userDocSnap = await getDoc(userDoc);
        // console.log("User doc snap: ", userDocSnap);
        if (userDocSnap.exists()) {
          //For personal data section
          const userData = userDocSnap.data();
          console.log("User data: ", userData);

          setRole(userData.role);

          setPersonalData({
            name: userData.name,
            surname: userData.surname,
            company: userData.company,
            email: userData.email,
          });

          setUserDefaultSettings({
            generativeModels: userData.default_settings.generative_models,
            embeddingCollections:
              userData.default_settings.embedding_collections,
            embeddingSamples: userData.default_settings.embedding_samples,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Execute logic
    if (currentUser) {
      fetchUserData();
      console.log(
        "In fetching user data --> User role: ",
        role,
        " User default settings: ",
        userDefaultSettings,
        " user personal data: ",
        personalData
      );
    } else {
      alert("Fetch user data error");
      navigate("/");
      return;
    }
  }, [currentUser]); // execute only if currentUser changes

  // FETCH AVAILABLE SETTINGS OPTIONS
  useEffect(() => {
    const fetchSettingsOptions = async () => {
      if (!role) {
        console.log("Not role found in fetch settings");
        return;
      }

      try {
        // Fetch user's role
        const settingsDoc = doc(firestore, "settings", role);
        let settingsDocSnap = await getDoc(settingsDoc);

        if (settingsDocSnap.exists()) {
          const settingsData = settingsDocSnap.data();

          setOptions({
            ...options,
            generativeModels: settingsData.generative_models,
            embeddingCollections: settingsData.embedding_collections,
            embeddingSamples: settingsData.embedding_samples,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentUser) {
      if (role) {
        fetchSettingsOptions();
        console.log(options);
        console.log("User role in fetch settings: ", role);
      }
    } else {
      alert("Fetch settings option error");
      navigate("/");
    }
  }, [currentUser, role]); // execute only if currentUser or role change

  // Handle Save Model Settings
  const handleSave = async () => {
    if (avatarFile) {
      const avatarRef = storageRef(
        storage,
        `avatars/${currentUser.uid}/${avatarFile.name}`
      );
      try {
        const snapshot = await uploadBytes(avatarRef, avatarFile);
        const avatarUrl = await getDownloadURL(snapshot.ref);

        // Combine the personal data and new settings into a single update object
        const updatedData = {
          default_settings: {
            generative_models:
              newSettings.generativeModels ||
              userDefaultSettings.generativeModels,
            embedding_collections:
              newSettings.embeddingCollections ||
              userDefaultSettings.embeddingCollections,
            embedding_samples:
              newSettings.embeddingSamples ||
              userDefaultSettings.embeddingSamples,
          },
          name: personalData.name,
          surname: personalData.surname,
          company: personalData.company,
          avatar: avatarUrl,
        };

        const userDoc = doc(firestore, "users", currentUser.uid);
        await setDoc(userDoc, updatedData, { merge: true });
        alert(
          "Settings and personal data successfully updated, including avatar!"
        );
      } catch (error) {}
    }

    try {
      // Combine the personal data and new settings into a single update object
      const updatedData = {
        default_settings: {
          generative_models:
            newSettings.generativeModels ||
            userDefaultSettings.generativeModels,
          embedding_collections:
            newSettings.embeddingCollections ||
            userDefaultSettings.embeddingCollections,
          embedding_samples:
            newSettings.embeddingSamples ||
            userDefaultSettings.embeddingSamples,
        },
        name: personalData.name,
        surname: personalData.surname,
        company: personalData.company,
      };

      const userDoc = doc(firestore, "users", currentUser.uid);
      await setDoc(userDoc, updatedData, { merge: true });
      alert("Settings and personal data successfully updated!");
    } catch (error) {
      console.error("Failed to save settings:", error);
      alert("Failed to update settings and personal data.");
    }
  };

  // Navigation to return to Copilot page
  const handleReturn = () => {
    navigate("/copilot");
  };

  console.log("User default settings: ", userDefaultSettings);

  return (
    <Container maxWidth="sm" fluid sx={{ marginTop: 4 }}>
      <Typography variant="h5" gutterBottom>
        User Data
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          type="file"
          accept="image/*"
          label="Upload Avatar"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleAvatarChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          margin="normal"
          value={personalData.name}
          onChange={(e) =>
            setPersonalData({ ...personalData, name: e.target.value })
          }
        />
        <TextField
          fullWidth
          label="Surname"
          variant="outlined"
          margin="normal"
          value={personalData.surname}
          onChange={(e) =>
            setPersonalData({ ...personalData, surname: e.target.value })
          }
        />
        <TextField
          fullWidth
          label="Company Name"
          variant="outlined"
          margin="normal"
          value={personalData.company}
          onChange={(e) =>
            setPersonalData({ ...personalData, company: e.target.value })
          }
        />
        <TextField
          disabled
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          value={personalData.email}
          InputProps={{
            readOnly: true,
          }}
          InputLabelProps={{
            shrink: true, // This ensures the label stays floated above the field
          }}
        />
        {role && (role === "developer" || role === "tester") && (
          <>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{ marginTop: 3 }}
            >
              <Typography variant="h5" gutterBottom>
                Model Options
              </Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel id="generative-model-label">
                  Generative Models
                </InputLabel>
                <Select
                  labelId="generative-model-label"
                  id="generativeModels"
                  label="Generative Models"
                  value={newSettings.generativeModels}
                  onChange={(e) =>
                    setNewSettings({
                      ...newSettings,
                      generativeModels: e.target.value,
                    })
                  }
                >
                  {options.generativeModels.map((model) => (
                    <MenuItem key={model} value={model}>
                      {model === userDefaultSettings.generativeModels
                        ? `${model} (default)`
                        : model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="embedding-collection-label">
                  Embedding Collections
                </InputLabel>
                <Select
                  labelId="embedding-collection-label"
                  value={newSettings.embeddingCollections}
                  id="embeddingCollections"
                  label="Embedding Collections"
                  onChange={(e) =>
                    setNewSettings({
                      ...newSettings,
                      embeddingCollections: e.target.value,
                    })
                  }
                >
                  {options.embeddingCollections.map((embed_col) => (
                    <MenuItem key={embed_col} value={embed_col}>
                      {embed_col === userDefaultSettings.embeddingCollections
                        ? `${embed_col} (default)`
                        : embed_col}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  placeholder={userDefaultSettings.embeddingSamples}
                  id="embedding-samples"
                  label="Embedding Samples"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Box>
          </>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate("/copilot")}
          >
            Return to Chat
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SettingsUser;
